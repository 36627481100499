/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: 'arial';
  // src: url('../fonts/AvenirLTStd-Light.eot');
  // src: url('../fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirLTStd-Light.woff') format('woff'),
  //   url('../fonts/AvenirLTS35.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'arial';
  // src: url('../fonts/arialeavy.eot');
  // src: url('../fonts/arialeavy.eot?#iefix') format('embedded-opentype'), url('../fonts/arialeavy.woff') format('woff'),
  //   url('../fonts/arialeavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

html,
body {
  color: var(--text-color);
  background-color: var(--primary-background-color);
  padding: 0;
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
}

/* width */
::-webkit-scrollbar {
  // width: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 1px;
}

@media screen and (max-width: 540px) {
  ::-webkit-scrollbar {
    width: 1px;
  }
}

.container {
  width: 80% !important;
  margin: auto;
  max-width: var(--max-width);
}

a {
  text-decoration: none;
  color: inherit;
}

@media screen and (max-width: 900px) {
  .container {
    width: 100% !important;
  }
}

@media screen and (min-width: 901px) and (max-width: 1200px) {
  .container {
    width: 90% !important;
  }
}

.font-family-bold {
  font-family: 'Avenir Heavy' !important;
}
