#loader-wrapper,
#loader-wrapper-preloading-without-image,
#loader-wrapper-preloading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100000000;
}
#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -130px 0 0 -100px;
  background-image: url('/assets/images/loader.gif');
  background-repeat: no-repeat;
  background-size: 150px;

  // -webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
  // animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

  z-index: 100000001;
}

#loader-wrapper .loader-bg,
#loader-wrapper-preloading-without-image .loader-bg,
#loader-wrapper-preloading .loader-bg {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 200px;
  height: 200px;
  margin: -125px 0 0 -125px;
  border-radius: 4px !important;
  background: #f8f8f8 !important;

  box-shadow: 1px 2px 20px;
}

#loader-wrapper .loader-logo-section,
#loader-wrapper-preloading-without-image .loader-logo-section,
#loader-wrapper-preloading .loader-logo-section {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 100px;
  height: 50px;
  margin: -90px 0 0 -50px;
  z-index: 100000002;
}

#loader-wrapper .loader-logo-section .loader-logo-img,
#loader-wrapper-preloading-without-image .loader-logo-section .loader-logo-img,
#loader-wrapper-preloading .loader-logo-section .loader-logo-img {
  position: relative;
  display: block;
  width: 100%;
}

#loader-wrapper .loader-msg-section,
#loader-wrapper-preloading-without-image .loader-msg-section,
#loader-wrapper-preloading .loader-msg-section {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 100px;
  margin: 0 0 0 -95px;
  z-index: 100000002;

  color: #e74c3c !important;
  text-align: center;
}

// #loader:before {
//   content: '';
//   position: absolute;
//   top: 5px;
//   left: 5px;
//   right: 5px;
//   bottom: 5px;
//   border-radius: 50%;
//   border: 3px solid transparent;
//   border-top-color: #e74c3c;

//   -webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
//   animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
// }

// #loader:after {
//   content: '';
//   position: absolute;
//   top: 15px;
//   left: 15px;
//   right: 15px;
//   bottom: 15px;
//   border-radius: 50%;
//   border: 3px solid transparent;
//   border-top-color: #f9c922;

//   -webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
//   animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
// }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(0deg); /* IE 9 */
    transform: rotate(0deg); /* Firefox 16+, IE 10+, Opera */
  }
  100% {
    -webkit-transform: rotate(360deg); /* Chrome, Opera 15+, Safari 3.1+ */
    -ms-transform: rotate(360deg); /* IE 9 */
    transform: rotate(360deg); /* Firefox 16+, IE 10+, Opera */
  }
}

#loader-wrapper .loader-section,
#loader-wrapper-preloading .loader-section {
  position: fixed;
  top: 0;
  width: 50%;
  height: 100%;
  background: #fff;
  opacity: 0.5;
  z-index: 100000000;
  -webkit-transform: translateX(0); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(0); /* IE 9 */
  transform: translateX(0); /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left,
#loader-wrapper-preloading-without-image .loader-section.section-left,
#loader-wrapper-preloading .loader-section.section-left {
  left: 0;
}

#loader-wrapper .loader-section.section-right,
#loader-wrapper-preloading-without-image .loader-section.section-right,
#loader-wrapper-preloading .loader-section.section-right {
  right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(-100%); /* IE 9 */
  transform: translateX(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateX(100%); /* IE 9 */
  transform: translateX(100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
  visibility: hidden;

  -webkit-transform: translateY(-100%); /* Chrome, Opera 15+, Safari 3.1+ */
  -ms-transform: translateY(-100%); /* IE 9 */
  transform: translateY(-100%); /* Firefox 16+, IE 10+, Opera */

  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}

.loader-msg-section-without-image {
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  height: 100px !important;
  top: 49% !important;
}

.loader-bg-without-image {
  height: 100px !important;
  left: 0 !important;
  right: 0 !important;
  margin: auto !important;
  height: 100px !important;
  top: 0 !important;
  bottom: 0 !important;
}
